import { Button, Col, message, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectCourseChaptersForUpdate,
  selectIsUpdateCourseChapters,
  selectModalCourseChapters,
} from "../../../../redux";
import { DataCourseChapters } from "../../../../redux/@types";
import { WrapperModalCourses } from "../../../../modules/courses/ui/styled";
import { useCourseChapters } from "../../../../modules";
import {
  InputNumber,
  InputSelect,
  InputText,
  InputTimePicker,
} from "../inputs";
import Upload from "../inputs/Upload";
import { DeleteOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const ModalCourseChapters = () => {
  const modalCities = useSelector(selectModalCourseChapters);
  const isUpdateCourseChapters = useSelector(selectIsUpdateCourseChapters);
  const updateCourseChapters = useSelector(selectCourseChaptersForUpdate);

  const {
    isLoading,
    changeModalCourseChaptersState,
    onSubmitCreateOrUpdate,
    arrSections,
  } = useCourseChapters();

  const {
    control,
    handleSubmit: onSubmit,
    setValue,
    watch,
  } = useFormContext<DataCourseChapters>();
  const nameVideo = watch("path_video");
  return (
    <WrapperModalCourses
      title={
        isUpdateCourseChapters
          ? `Actualizando capitulo: '${updateCourseChapters?.id!}'`
          : `Creando capitulo`
      }
      open={modalCities}
      width={"320px"}
      destroyOnClose
      onClose={changeModalCourseChaptersState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText placeHolder="Capitulo 1" name="name" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Video:
        </Title>
        {nameVideo ? (
          <Title style={{ fontSize: "0.81rem" }} level={5}>
            {nameVideo}
          </Title>
        ) : (
          <Title style={{ fontSize: "0.81rem" }} level={5}>
            No se tiene video registrado
          </Title>
        )}
         <div style={{ width:"100%", display:"flex" }}>
            <Upload
              fileMaxSize={9999999}
              placeHolder="Cargar video"
              filePath="path_video"
              onUploadedFile={(files) => {
                // console.log({ files });
                const file = files[0];
                setValue("path_video", file.fileKey);
                message.success("Se ha actualizado el video");
              }}
            />
            <Button
              type="primary"
              danger
              style={{marginLeft: "1rem"}}
              onClick={() => setValue("path_video", "")}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </div>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Posición:
        </Title>
        <InputNumber placeHolder="8" name="position" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Sección del curso:
        </Title>
        <InputSelect
          name="course_section_id"
          control={control}
          dataOptions={
            arrSections?.map((item) => ({
              value: item.id.toString(),
              title: item.name,
            })) || []
          }
          label="Sección del curso"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Tipo:
        </Title>
        <InputSelect
          name="type"
          control={control}
          dataOptions={[
            { title: "Gratis", value: "free" },
            { title: "Pago", value: "paid" },
          ]}
          label="Tipo"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Duración del capitulo:
        </Title>
        <InputTimePicker
          control={control}
          name="duration"
          placeHolder="Duración"
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateCourseChapters ? "Actualizar información" : "Crear capitulo"}
      </Button>
    </WrapperModalCourses>
  );
};
