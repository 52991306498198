// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState } from "react";
import { FilePdfOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, message, Table, Typography } from "antd";
import { EyeOutlined, UploadOutlined, UndoOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useOrderById } from "../../hooks/orderById";
import { InputTextSimple, ModalOrdersShipped } from "../../../../shared/components";
import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import {
  selectOrderForUpdate,
  selectOrderShippedForUpdate,
  selectOrderShippedModalIsOpen,
  selectOrderShipping,
} from "../../../../redux/selectors/orders.selectors";
import { shippingService } from "../../../../shared/services";
import {
  changeOrderChangeShipping,
  setOrdersForUpdate,
} from "../../../../redux";
import { decimal } from "../../../../shared/utils/Helper";
import Upload from "../../../../shared/components/ui/inputs/Upload";
import { PATH_S3_BUCKET } from "../../../../shared/utils/constants";

const { Title } = Typography;

interface IPropsInitialStateForm{
  delivery_number: undefined | string
  fulfillment_company_delivery_guide: undefined | string
}

const initialStateForm:IPropsInitialStateForm ={
  delivery_number: undefined,
  fulfillment_company_delivery_guide: undefined
}


export const Shippings = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditableGuideOrPDF, setIsEditableGuideOrPDF] = useState(false)
  const [idForEditGuideOrPDF, setIdForEditGuideOrPDF] = useState<number | null>(null)
  const [formUpdateGuide, setFormUpdateGuide] = useState<IPropsInitialStateForm>(initialStateForm)
  const { delivery_number, fulfillment_company_delivery_guide } = formUpdateGuide
  const modalOrdersShipped = useSelector(selectOrderShippedModalIsOpen);
  const orderShippingForUpdate = useSelector(selectOrderForUpdate);
  const orderForUpdate = useSelector(selectOrderShippedForUpdate);
  const orderShipping = useSelector(selectOrderShipping);
  const { changeOrderModal, changeOrderShippedModal, orderShippingShow } =
    useOrderById();
  const status = [3, 5, 6, 7];
  // console.log({orderShipping})
  const getRefreshSingleOrder = async () => {
    if (isLoading) return;
    setIsLoading(true);
    getArrOfShipping().then(() => {
      setIsLoading(false);
    });
  };

 

  const getArrOfShipping = async () => {
    await shippingService
      .find({
        query: { order_id: params?.id, $limit: 9999999999999 },
      })
      //   .then((res: IShippingFin) =>
      .then((res: any) => dispatch(changeOrderChangeShipping(res.data)))
      .catch((err: any) => {
        console.log(err);
        message.error(err.message);
      });
  };

  useEffect(() => {
    getArrOfShipping();
  }, []);

  const changeStateOfEditableGuideOrPDF = () => setIsEditableGuideOrPDF(e=>!e)

  const patchOrderGuideOrPdf = async() =>{
    // console.log({idForEditGuideOrPDF, formUpdateGuide})
    return await shippingService
    .patch(idForEditGuideOrPDF, formUpdateGuide,{ query: { $client: { upDeliveryNumber: 'true' } } })
    .then((res: any) => {
      setFormUpdateGuide(initialStateForm)
      changeStateOfEditableGuideOrPDF()
      getRefreshSingleOrder()
      message.success("se ha guardo la información con éxito")
    })
    .catch((err: any) => {
      console.log(err);
      message.error(err.message);
    });

  }

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "filterByID",
    },
    {
      title: "",
      key: "show_sons",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <EyeOutlined
              style={{ fontSize: "27px", cursor: "pointer" }}
              onClick={() => changeOrderShippedModal(item)}
            />
          </Col>
        );
      },
    },
    {
      title: "Transportado por",
      dataIndex: ["fulfillment_company", "name"],
      key: "transported_by",
    },
    {
      title: "Total costo envío",
      dataIndex: "price",
      key: "total_shipping_cost",
      render: (item) => (item ? `${decimal(item)}` : "Precio sin asignar"),
    },
    {
      title: "Número de guía",
      dataIndex: "delivery_number",
      key: "delivery_number",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              item.id !== idForEditGuideOrPDF && isEditableGuideOrPDF === false && (
                `${!!item ? item.delivery_number : "Número sin definir"}`
              )}
              {
                item.id === idForEditGuideOrPDF && isEditableGuideOrPDF && (
                <>
                <InputTextSimple placeHolder="Escribe el No. de guía aquí"  value={delivery_number} onChange={(e)=> setFormUpdateGuide(item=>({...item, delivery_number: e.target.value }))}/>
                </>
              )}
          </Col>
        );
      },
    },
    {
      title: "Estado",
      dataIndex: ["shipping_status", "name"],
      key: "status",
    },
    {
      title:"PDF de la guía",
      dataIndex: ["fulfillment_company_delivery_guide"],
      key: "fulfillment_company_delivery_guide",
      render: (_, item) => {
        // console.log({ item });
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              item.id !== idForEditGuideOrPDF && isEditableGuideOrPDF === false && (
                <FilePdfOutlined style={{ cursor: "pointer" }} onClick={()=>{if(item.fulfillment_company_delivery_guide){
                  window.open(`https://yoyoso-static.s3.amazonaws.com/${item.fulfillment_company_delivery_guide}`, '_blank')
                }}}/>
              )}
              {
                item.id === idForEditGuideOrPDF && isEditableGuideOrPDF && (
                  <div style={{display:"flex", flexDirection: "column", justifyContent:"center"}}>
                    {fulfillment_company_delivery_guide && fulfillment_company_delivery_guide.substring(79)  }
                      <Upload
                      fileMaxSize={9999999}
                      placeHolder="Cargar PDF"
                      filePath="fulfillment_company_delivery_guide"
                      onUploadedFile={(files) => {
                        // console.log({ files });
                        const file = files[0];
                        // console.log({file})
                        setFormUpdateGuide(item=>({...item, fulfillment_company_delivery_guide: file.fileKey}))
                        message.success("Se ha cargado el PDF");
                      }}
                    />
                    <Button
                      type="primary"
                      danger
                      style={{marginLeft: "1rem"}}
                      onClick={() => setFormUpdateGuide(item=>({...item, fulfillment_company_delivery_guide: ""}))}
                      shape="circle"
                      icon={<DeleteOutlined />}
                      size="large"
                    />
                  </div>
                )}
          </Col>
        );
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            {
              item.id === idForEditGuideOrPDF && isEditableGuideOrPDF && (
              <>
                <Button
                  type="dashed"
                  onClick={() => {
                    setIdForEditGuideOrPDF(null)
                    patchOrderGuideOrPdf()
                  }}
                  shape="circle"
                  icon={<SaveOutlined />}
                  size="large"
                />
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      setIdForEditGuideOrPDF(null)
                      changeStateOfEditableGuideOrPDF()
                    }}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    size="large"
                  />
              </>
              )
            }
            {
              item.id !== idForEditGuideOrPDF && isEditableGuideOrPDF === false && (
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => {
                    setIdForEditGuideOrPDF(item.id)
                    changeStateOfEditableGuideOrPDF()
                  }}
                  icon={<EditOutlined />}
                  size="large"
                />
              )}
          </Col>
        );
      },
    },
  ];
  return (
    <Col
      span={24}
      style={{
        width: "100%",
        display: "flex",
        margin: "auto",
        // padding: 2.5,
        justifyContent: "flex-start",
        alignItems: "flex-end",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        {status.includes(orderShippingForUpdate?.order_status_id) ||
        (orderShippingForUpdate?.fulfillment_company_id === 5 &&
          orderShippingForUpdate?.payment_method === "pay_in_store" &&
          orderShippingForUpdate?.order_status_id &&
          !![12, 13].includes(orderShippingForUpdate?.order_status_id)) ? (
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={changeOrderModal}
          >
            Generar
          </Button>
        ) : (
          <Title
            style={{
              marginBottom: "3px",
              color: "var(--dark)",
              fontSize: "0.76rem",
            }}
            level={5}
          >
            El estado actual del pedido no permite generar envíos.
          </Title>
        )}
        <div style={{ flexGrow: 1 }} />
        <Button
          style={{ marginBottom: "5px" }}
          type="primary"
          shape="circle"
          onClick={getRefreshSingleOrder}
          disabled={isLoading}
          icon={<UndoOutlined />}
        />
      </div>
      <Table
        style={{ width: "100%" }}
        pagination={false}
        bordered
        columns={columns}
        dataSource={orderShipping}
      />
      {modalOrdersShipped && orderShippingShow && (
        <ModalOrdersShipped getRefreshSingleOrder={getRefreshSingleOrder} orderShippingShow={orderShippingShow} />
      )}
    </Col>
  );
};
